<template>
  <div class="content" v-if="!checkMsg && !loading">
    <div class="background-none u_p-t10 title">
      <span class="u_p-l12 u_fw--bold u_fz--large">{{ $t(title) }}</span>
    </div>
    <div>
      <div class="background-day">
        <div
          v-for="(setting, key) in settings"
          :key="'settings' + key"
          class="flex flex-center flex-justify-between flex-wrap"
        >
          <div class="u_w--100p u_m--l10 text-gray u_m--b10">
            {{ $t(setting.name) }}
            <div v-if="setting.subNote" class="sub_note">({{ $t(setting.subNote) }})</div>
          </div>
          <div class="u_w--100p">
            <div :class="['tab-header', {'is-single': setting.items.length < 2}]">
              <div :class="['popup-block choose-block']">
                <span v-for="(item, i) in setting.items" :key="'item' + i">
                  <template v-if="!setting.isMember">
                    <input :id="key + i" type="radio" v-model="info[key]" :value="+item" />
                    <label
                      :for="key + i"
                      class="tab_title_btn u_c--pointer"
                      @click="setting.customItem.indexOf(i) > -1 && openCustom(key)"
                    >
                      <span
                        v-if="item && lang !== 'zh-CN' && setting.unitTxt === 'ui_each'"
                        v-text="`${getUnit(setting.unitTxt)}`"
                      ></span>
                      {{ item || $t('ui_custom') }}
                      <span
                        v-if="item && setting.unitTxt === 'ui_each' && lang === 'zh-CN'"
                        v-text="getUnit(setting.unitTxt)"
                      ></span>
                      <span
                        v-if="item && setting.unitTxt !== 'ui_each'"
                        v-text="`${getUnit(setting.unitTxt)}`"
                      ></span>
                    </label>
                  </template>
                  <template v-if="setting.isMember">
                    <input :id="key + i" type="radio" :value="item" />
                    <label :for="key + i" class="tab_title_btn u_c--pointer" @click="openMember">
                      {{ $t(item) || $t('ui_custom') }}{{ item && getUnit(setting.unitTxt) }}
                    </label>
                  </template>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-center u_m--l10 u_m--r10 u_m--b10">
          <div class="text-gray">
            {{ $t('ui_total_sent_amount') }}
          </div>
          <div class="text-gray u_m--l10" style="font-size: 16px;font-weight: bold;">
            <span class="text-gold">{{ totalAccount }}</span>
            <span>{{ getUnit('ui_dollar') }}</span>
          </div>
          <div class="text-gray u_m--l10" style="font-size: 12px">
            <!-- <span class="j_txt--red">*</span> -->
            ({{ $t('ui_deducted_from_your_account_immediately') }})
          </div>
        </div>
        <div class="flex flex-center flex-justify-between u_m-t12 u_m--l10 u_m--r10">
          <div class="text-tomato" style="font-size: 12px">
            <!-- <span class="j_txt--red">*</span> -->
            <i class="el-icon-info">*</i>
            {{ $t('ui_text_041') }}
          </div>
        </div>
      </div>
      <div class="flex flex-center u_m--t25">
        <el-button class="main-btn" :type="'default'" @click="update" v-text="$t('btn_confirm')" />
      </div>
    </div>
    <!-- customDialog -->
    <el-dialog
      :zIndex="10"
      v-model="customDialog"
      :close-on-press-escape="false"
      :closeable="true"
      custom-class="custom-popup"
      width="500px"
      :append-to-body="true"
      :destroy-on-close="true"
    >
      <template #title>
        <el-row
          :class="`u_p--t15 u_p--b10 background-day`"
          type="flex"
          align="center"
          justify="center"
        >
          <span class="u_t--center title" style="font-weight: 600;">{{ $t('ui_custom') }}</span>
        </el-row>
      </template>
      <!--  -->
      <el-row class="u_w--100p">
        <div class="u_w--100p u_p--t15">
          <el-input
            v-model="customValue"
            :label="$t('ui_please_enter_custom_number')"
            :border="true"
            placeholder=""
            label-width="70%"
            input-align="right"
            v-on="$root.inputEvents"
          />
        </div>
      </el-row>

      <template #footer>
        <el-row
          type="flex"
          align="center"
          justify="center"
          class="u_p-l12 u_p-r12 u_w--100p footer-block"
        >
          <el-col class="u_t--center flex flex-center" :span="12">
            <el-button
              class="u_fw--bold text-primary background-button cancel-btn"
              @click="back"
              type="default"
              size="large"
              >{{ $t('btn_back') }}</el-button
            >
          </el-col>
          <el-col class="u_t--center flex flex-center" :span="12">
            <el-button type="primary" size="large" class="main-btn" @click="confirm">
              {{ $t('btn_confirm') }}
            </el-button>
          </el-col>
        </el-row>
      </template>
    </el-dialog>
    <!-- memberDialog -->
    <el-dialog
      :zIndex="10"
      round
      v-model="memberDialog"
      :close-on-press-escape="false"
      :closeable="true"
      custom-class="custom-popup choose-user"
      width="500px"
      :append-to-body="true"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      @open="backUser"
    >
      <template #title>
        <el-row
          :class="`u_p--t15 u_p--b10 background-day`"
          type="flex"
          align="center"
          justify="center"
        >
          <span class="u_t--center title" style="font-weight: 600;">{{ $t('ui_text_042') }}</span>
        </el-row>
      </template>
      <el-row class="u_m--b10 u_w--100p">
        <div class="u_w--100p u_p--t15">
          <el-input
            v-model="userName"
            label=""
            :border="true"
            :placeholder="$t('ui_hint_user_acc')"
            @keyup.enter="chooseAllUser"
            class="choose-user"
          >
            <template #suffix v-if="getComputed(memberList).length">
              <el-checkbox v-model="allUser" shape="square">{{ $t('ui_select_all') }}</el-checkbox>
            </template>
          </el-input>
          <div class="u_m--t15">
            <div class="u_m--b15">
              <div class="border" v-if="!getComputed(memberList).length">
                {{ $t('ui_wap_text_050') }}
              </div>
              <div class="tab-header">
                <div
                  :class="[
                    'popup-block is-popup',
                    fillColum !== fillRowNum ? `fill-${fillColum}` : ''
                  ]"
                >
                  <span v-for="(member, i) in getComputed(memberList)" :key="'item' + i">
                    <transition-group name="van-slide-up" mode="out-in">
                      <input
                        :id="'member' + i"
                        type="checkbox"
                        v-model="chooseUser"
                        :value="+member.user_id"
                        :key="'checkbox_input' + i"
                        :name="'member' + i"
                      />
                      <label
                        :for="'member' + i"
                        class="tab_title_btn tab_title_btn_w u_c--pointer"
                        :key="'checkbox_label' + i"
                      >
                        {{ member.user_name }}
                      </label>
                    </transition-group>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-row
          type="flex"
          align="center"
          justify="center"
          class="u_p--l10 u_p--r10 u_w--100p footer-block"
          style="position: sticky; bottom: 0;"
        >
          <el-col class="u_t--center flex flex-center" :span="12">
            <el-button
              class="u_fw--bold text-primary background-button cancel-btn"
              @click="backUser"
              type="default"
              size="large"
            >
              {{ $t('btn_back') }}
            </el-button>
          </el-col>
          <el-col class="u_t--center flex flex-center" :span="12">
            <el-button class="main-btn" type="primary" size="large" @click="confirmUser">
              {{ $t('btn_submit') }}</el-button
            >
          </el-col>
        </el-row>
      </el-row>
    </el-dialog>
  </div>
  <div v-else-if="loading">
    <el-loading style="position: absolute;top:50%;left: 50%;right: 0; margin:auto;"></el-loading>
  </div>
  <el-empty v-else :description="checkMsg" />
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {App, Format} from '@/services';
import {
  ElButton,
  ElDialog,
  ElInput,
  ElLoading,
  ElCheckbox,
  ElMessage,
  ElEmpty,
  ElMessageBox
} from 'element-plus';
import {Watch, Prop} from 'vue-property-decorator';
import {luckmoney} from '@/services/server/wap/luckmoney';
import {
  ISettings,
  ILuckMoney,
  ILuckMoneyResult,
  ILuckMoneyUpdatedResult,
  ILuckMoneyMember,
  ILuckMoneyMemberResult
} from '@/model/luckmoney';

@Options({
  components: {
    ElInput,
    ElDialog,
    ElButton,
    ElEmpty,
    ElLoading,
    ElCheckbox
  }
})
export default class ProxyCommon extends Vue {
  @Prop()
  title!: string;

  @Prop()
  settings!: {
    type?: number;
    active_day?: ISettings;
    threshold: ISettings;
    price: ISettings;
    num: ISettings;
    not_login_day?: ISettings;
    key?: string;
  };
  @Prop()
  _info!: {
    choose_user?: number[];
    type: number;
    active_day: number | undefined;
    price: number | undefined;
    num: number | undefined;
    threshold: number | undefined;
  };

  config = App.getConfig() || {
    currency: {
      id: '',
      code: 'CNY',
      text: ''
    },
    dollarSign: '¥'
  };
  // @Prop(String) userName = '';
  // @Prop() chooseUser = [];
  // @Prop(Boolean) allUser = false;
  // @Prop()
  // customItem!: ISettings;

  container = null;
  customValue = '';
  customDialog = false;
  memberDialog = false;
  userName = '';
  chooseUser: number[] = [];
  allUser = false;
  customItem: ISettings = {
    name: '',
    unitTxt: '',
    items: [0],
    itemHilight: 0,
    customItem: [0],
    key: ''
  };
  loading = false;
  checkMsg = '';
  tabmenus = [
    {
      name: 'ui_act_rp',
      routeName: 'ProxyReport'
    },
    {
      name: 'ui_reg_act_back',
      routeName: 'ProxyReigster',
      key: 'type1'
    },
    {
      name: 'ui_text_028',
      routeName: 'ProxyEverday',
      key: 'type2'
    },
    {
      name: 'ui_text_029',
      routeName: 'ProxyOldBoy',
      key: 'type3'
    }
  ];
  lang = localStorage.getItem('language');
  info: {
    choose_user?: number[];
    type: number;
    active_day: number | undefined;
    price: number | undefined;
    num: number | undefined;
    threshold: number | undefined;
    not_login_day?: number | undefined;
  } = {
    type: 2,
    active_day: 1,
    price: 0,
    num: 0,
    threshold: 0,
    choose_user: []
  };
  memberList: ILuckMoneyMember[] = [];
  get totalAccount() {
    return Format.formatNumber((this.info.price || 0) * (this.info.num || 0), 0);
  }
  get fillColum() {
    // console.log(this.fillRowNum, 'fillRowNum', this.getComputed(this.memberList).length % this.fillRowNum)
    return this.fillRowNum - (this.getComputed(this.memberList).length % this.fillRowNum);
  }
  get fillRowNum() {
    const h = Math.max(window.innerWidth, document.documentElement.clientWidth);
    return Math.floor(h / 120);
  }
  @Watch('allUser')
  onAllUserChange(val: boolean) {
    if (val) {
      console.log(this.getComputed(this.memberList), ' this.getComputed(this.memberList)');
      const chooseUser: number[] = [];
      this.getComputed(this.memberList).map((v: any) => {
        chooseUser.push(v.user_id);
        return v;
      });
      this.chooseUser = chooseUser;
    } else {
      this.chooseUser = [];
    }
  }
  @Watch('chooseUser.length')
  onChooseUserChange(val: number) {
    if (val === this.getComputed(this.memberList).length) {
      this.allUser = true;
    } else {
      this.allUser = false;
    }
  }
  @Watch('userName')
  onUserNameChange() {
    this.allUser = this.getComputed(this.memberList).length === this.chooseUser.length;
    console.log('userName change');
  }
  mounted() {
    this.doCheck();
    this.$nextTick(() => {
      console.log(this.info, ' ...this.info,');
      this.info = {
        ...this._info,
        active_day: this.settings.active_day?.items && +this.settings.active_day?.items[0],
        price: this.settings.price?.items && +this.settings.price?.items[0],
        num: this.settings.num?.items && +this.settings.num?.items[0],
        threshold: this.settings.threshold?.items && +this.settings.threshold?.items[0]
      };
      console.log(this.info, 'this.info', this.settings, 'settings');
    });
  }
  async doCheck() {
    this.loading = true;
    const data = await luckmoney.read<ILuckMoneyResult>();
    this.loading = false;
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      if (typeof this.$route.name === 'string') {
        this.checkSetting(this.$route.name, data);
      }
    }
  }
  checkSetting(name: string, data: ILuckMoneyResult) {
    const menu = this.tabmenus.filter(v => v.routeName === name)[0];
    if (Array.isArray(data.list)) {
      let typeSetting: ILuckMoney = {
        error_msg: '',
        status: 0
      };
      switch (menu.key) {
        case 'type1':
          typeSetting = data.list[0].type1;
          break;
        case 'type2':
          typeSetting = data.list[0].type2;
          break;
        case 'type3':
          typeSetting = data.list[0].type3;
          break;
      }

      this.checkMsg = typeSetting ? typeSetting.error_msg : '';
      // if (typeSetting.error_msg) {
      //   this.$router.push({
      //     name: 'ProxyReport'
      //   });
      // }
    }
  }
  unitMap = new Map([
    ['USD', 'ui_dollar_usd'],
    ['VND', 'ui_dollar_vnd'],
    ['THB', 'ui_dollar_thb'],
    ['CNY', 'ui_dollar_cny']
  ]);
  get dollarUnit() {
    const code = this.config.currency ? this.config.currency.code : 'CNY';
    return this.unitMap.get(code);
  }

  getUnit(key: string) {
    if (key) {
      return this.$t(key, {
        dollar: this.$t(this.dollarUnit || 'ui_dollar_cny')
      });
    } else {
      return '';
    }
  }
  openCustom(key: string) {
    //this.$nextTick:避免自订尚未更新就赋值给customValue(tmp)
    this.$nextTick(() => {
      this.customDialog = true;
      switch (key) {
        case 'active_day':
          if (this.settings.active_day) {
            const obj = {
              ...this.settings.active_day,
              key: key
            };
            this.customItem = obj;
            this.customValue = this.info.active_day + '' || '';
          }
          break;
        case 'price':
          this.customItem = this.settings.price || {
            name: '',
            unitTxt: '',
            items: [0],
            itemHilight: 0,
            customItem: [0],
            key: ''
          };
          this.customItem.key = key;
          this.customValue = this.info.price + '' || '';
          break;
        case 'num':
          this.customItem = this.settings.num || {
            name: '',
            unitTxt: '',
            items: [0],
            itemHilight: 0,
            customItem: [0],
            key: ''
          };
          this.customItem.key = key;
          this.customValue = this.info.num + '' || '';
          break;
        case 'threshold':
          this.customItem = this.settings.threshold || {
            name: '',
            unitTxt: '',
            items: [0],
            itemHilight: 0,
            customItem: [0],
            key: ''
          };
          this.customItem.key = key;
          this.customValue = this.info.threshold + '' || '';
          break;
        case 'not_login_day':
          this.customItem = this.settings.not_login_day || {
            name: '',
            unitTxt: '',
            items: [0],
            itemHilight: 0,
            customItem: [0],
            key: ''
          };
          this.customItem.key = key;
          this.customValue = this.info.not_login_day + '' || '';
          break;
      }
      // this.customItem = this.settings[key];
      // this.customItem.key = key;
      // this.customValue = this.info[key] || '';
    });
  }
  confirm() {
    const setting = this.customItem;
    setting.items.splice(-1, 1, this.customValue); //tmp set view
    switch (setting.key) {
      case 'active_day':
        this.info.active_day = +this.customValue || 0;
        break;
      case 'price':
        this.info.price = +this.customValue || 0;
        break;
      case 'num':
        this.info.num = +this.customValue || 0;
        break;
      case 'threshold':
        this.info.threshold = +this.customValue || 0;
        break;
      case 'not_login_day':
        this.info.not_login_day = +this.customValue || 0;
        break;
    }
    // this.info[setting.key] = +this.customValue || ''; //tmp set data
    this.customDialog = false;
  }
  back() {
    this.customDialog = false;
    // this.customKey = '';
  }
  async update() {
    const txt = [];
    const keys = Object.entries(this.info);
    for (const [key, value] of keys) {
      if (!value || (Array.isArray(value) && !value.length)) {
        txt.push('');
      }
    }
    if (txt.length > 0) {
      const opts = {
        confirmButtonText: this.$t('btn_confirm'),
        title: this.$t('sysmsg_please_check_the_activity_settings'),
        message: 'Error',
        zIndex: 3000
      };
      ElMessageBox({
        ...opts,
        message: this.$t('checkSetting')
      });
    } else {
      const data = await luckmoney.updated<ILuckMoneyUpdatedResult>(this.info);
      if (data instanceof Error) {
        ElMessage({type: 'error', message: data.message});
      } else {
        ElMessage(data.message);
        this.doCheck();
      }
    }
  }
  //for 每日送 everyday
  async openMember() {
    console.log(this.info.type, 'this.info.type');
    const data = await luckmoney.member<ILuckMoneyMemberResult>({type: this.info.type});
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.memberList = data.list;
    }
    this.allUser = false;
    this.memberDialog = true;
  }
  backUser(e: unknown) {
    console.log('e: ', e);
    if (e) {
      this.memberDialog = false;
    }
    this.userName = '';
    this.chooseUser = [];
  }
  confirmUser() {
    console.log(this.chooseUser, 'chooseUser');
    if (this.chooseUser.length > 0) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.info.choose_user = this.chooseUser.map(v => v);
      this.memberDialog = false;
    } else {
      ElMessage({
        message: this.$t('ui_choice_member')
      });
    }
  }
  getComputed(list: {user_name: string}[]) {
    return list.filter(v => v.user_name.indexOf(this.userName) > -1).slice(0, 500);
  }
  chooseAllUser() {
    this.allUser = !this.allUser;
  }
  termClick(name: string) {
    this.$router.push({name});
  }
}
</script>

<style lang="scss" scoped>
.u_fz--large {
  font-size: 24px;
}
.u_fw--bold {
  font-weight: bold;
}
.text-gold {
  color: $gold;
}
.content {
  font-size: 14px /* 14/25 */;
  .title {
    font-size: 14px /* 14/25 */;
    font-weight: 600;
    color: #333333;
    padding: 20px /* 20/25 */ 0;
  }
}
.tab-header {
  margin-bottom: 16px /* 16/25 */;
  input {
    display: none;
  }
  :checked + label {
    color: $bg;
    background-color: rgba(31, 85, 240, 0.1);
    border: 0;
    border-radius: 8px /* 8/25 */;
  }
}
.text-gray {
  color: #999999;
}
:deep(.van-divider) {
  margin-top: 0;
}
.tab_title_btn {
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc((100% - 51px /* 51/25 */) / 4);
  display: inline-block;
  text-align: center;
  line-height: 32px /* 32/25 */;
  height: 32px /* 32/25 */;
  color: #000000;
  border: 1px /* 1/25 */ solid #dddddd;
  border-radius: 8px /* 8/25 */;
  box-sizing: border-box;
  margin: 0 4.5px /* 4.5/25 */;
}
.custom-popup {
  .title {
    font-size: 18px !important;
  }
  .cancel-btn,
  .main-btn {
    width: calc(100% - 24px /* 24/25 */);
    height: 48px /* 48/25 */;
    margin: auto;
    border-radius: 8px /* 8/25 */;
    font-size: 16px;
  }
  .main-btn {
    background-color: $bg;
    color: $cf;
    border-width: 0;
  }
  .footer-block {
    margin-top: 32px /* 32/25 */;
    margin-bottom: 12px /* 12/25 */;
  }
  :deep(.van-cell__title) {
    @extend .over2;
  }
  .choose-user :deep(.el-input__suffix) {
    display: flex;
    align-items: center;
  }
}

.main-btn {
  width: calc(100% - 24px /* 24/25 */);
  height: 48px /* 48/25 */;
  color: $cf;
  margin: auto 12px /* 12/25 */ 50px /* 50/25 */;
  background-color: $bg;
  border-radius: 8px /* 8/25 */;
  font-size: 16px;
  border-width: 0;
}
.el-dialog__footer .main-btn {
  margin: auto !important;
}
.u_m--l10 {
  margin-left: 10px;
}
.u_m--r10 {
  margin-right: 10px;
}
.u_m--b10 {
  margin-bottom: 10px;
}
.u_m--t15 {
  margin-top: 15px;
}
.u_m--t25 {
  margin-top: 25px;
}
.u_p--t15 {
  padding-top: 15px;
}
.u_p--l10 {
  padding-left: 10px;
}
.u_p--r10 {
  padding-right: 10px;
}
.u_p--b10 {
  padding-bottom: 10px;
}
</style>
