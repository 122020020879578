// Lucky_money/set_active

import doFetch from '../../fetch';
const GAMEPAHT = '/api/game';

const actions = {
  read: async <T>() => await doFetch<T>(`${GAMEPAHT}/v1/Lucky_money/check_set_active`, 'POST', {}),
  updated: async <T>(data: any) =>
    await doFetch<T>(`${GAMEPAHT}/v1/Lucky_money/set_active`, 'POST', data),
  member: async <T>(data: any) =>
    await doFetch<T>(`${GAMEPAHT}/v1/Lucky_money/get_member_list`, 'POST', data),
  report: async <T>(data: any) =>
    await doFetch<T>('/api/game/v1/Lucky_money/get_report_list', 'POST', data),
  deleted: async <T>(data: any) =>
    await doFetch<T>(`${GAMEPAHT}/v1/Lucky_money/stop_active`, 'POST', data)
};

export const luckmoney = actions;
