
import {Options, Vue} from 'vue-class-component';
import {App, Format} from '@/services';
import {
  ElButton,
  ElDialog,
  ElInput,
  ElLoading,
  ElCheckbox,
  ElMessage,
  ElEmpty,
  ElMessageBox
} from 'element-plus';
import {Watch, Prop} from 'vue-property-decorator';
import {luckmoney} from '@/services/server/wap/luckmoney';
import {
  ISettings,
  ILuckMoney,
  ILuckMoneyResult,
  ILuckMoneyUpdatedResult,
  ILuckMoneyMember,
  ILuckMoneyMemberResult
} from '@/model/luckmoney';

@Options({
  components: {
    ElInput,
    ElDialog,
    ElButton,
    ElEmpty,
    ElLoading,
    ElCheckbox
  }
})
export default class ProxyCommon extends Vue {
  @Prop()
  title!: string;

  @Prop()
  settings!: {
    type?: number;
    active_day?: ISettings;
    threshold: ISettings;
    price: ISettings;
    num: ISettings;
    not_login_day?: ISettings;
    key?: string;
  };
  @Prop()
  _info!: {
    choose_user?: number[];
    type: number;
    active_day: number | undefined;
    price: number | undefined;
    num: number | undefined;
    threshold: number | undefined;
  };

  config = App.getConfig() || {
    currency: {
      id: '',
      code: 'CNY',
      text: ''
    },
    dollarSign: '¥'
  };
  // @Prop(String) userName = '';
  // @Prop() chooseUser = [];
  // @Prop(Boolean) allUser = false;
  // @Prop()
  // customItem!: ISettings;

  container = null;
  customValue = '';
  customDialog = false;
  memberDialog = false;
  userName = '';
  chooseUser: number[] = [];
  allUser = false;
  customItem: ISettings = {
    name: '',
    unitTxt: '',
    items: [0],
    itemHilight: 0,
    customItem: [0],
    key: ''
  };
  loading = false;
  checkMsg = '';
  tabmenus = [
    {
      name: 'ui_act_rp',
      routeName: 'ProxyReport'
    },
    {
      name: 'ui_reg_act_back',
      routeName: 'ProxyReigster',
      key: 'type1'
    },
    {
      name: 'ui_text_028',
      routeName: 'ProxyEverday',
      key: 'type2'
    },
    {
      name: 'ui_text_029',
      routeName: 'ProxyOldBoy',
      key: 'type3'
    }
  ];
  lang = localStorage.getItem('language');
  info: {
    choose_user?: number[];
    type: number;
    active_day: number | undefined;
    price: number | undefined;
    num: number | undefined;
    threshold: number | undefined;
    not_login_day?: number | undefined;
  } = {
    type: 2,
    active_day: 1,
    price: 0,
    num: 0,
    threshold: 0,
    choose_user: []
  };
  memberList: ILuckMoneyMember[] = [];
  get totalAccount() {
    return Format.formatNumber((this.info.price || 0) * (this.info.num || 0), 0);
  }
  get fillColum() {
    // console.log(this.fillRowNum, 'fillRowNum', this.getComputed(this.memberList).length % this.fillRowNum)
    return this.fillRowNum - (this.getComputed(this.memberList).length % this.fillRowNum);
  }
  get fillRowNum() {
    const h = Math.max(window.innerWidth, document.documentElement.clientWidth);
    return Math.floor(h / 120);
  }
  @Watch('allUser')
  onAllUserChange(val: boolean) {
    if (val) {
      console.log(this.getComputed(this.memberList), ' this.getComputed(this.memberList)');
      const chooseUser: number[] = [];
      this.getComputed(this.memberList).map((v: any) => {
        chooseUser.push(v.user_id);
        return v;
      });
      this.chooseUser = chooseUser;
    } else {
      this.chooseUser = [];
    }
  }
  @Watch('chooseUser.length')
  onChooseUserChange(val: number) {
    if (val === this.getComputed(this.memberList).length) {
      this.allUser = true;
    } else {
      this.allUser = false;
    }
  }
  @Watch('userName')
  onUserNameChange() {
    this.allUser = this.getComputed(this.memberList).length === this.chooseUser.length;
    console.log('userName change');
  }
  mounted() {
    this.doCheck();
    this.$nextTick(() => {
      console.log(this.info, ' ...this.info,');
      this.info = {
        ...this._info,
        active_day: this.settings.active_day?.items && +this.settings.active_day?.items[0],
        price: this.settings.price?.items && +this.settings.price?.items[0],
        num: this.settings.num?.items && +this.settings.num?.items[0],
        threshold: this.settings.threshold?.items && +this.settings.threshold?.items[0]
      };
      console.log(this.info, 'this.info', this.settings, 'settings');
    });
  }
  async doCheck() {
    this.loading = true;
    const data = await luckmoney.read<ILuckMoneyResult>();
    this.loading = false;
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      if (typeof this.$route.name === 'string') {
        this.checkSetting(this.$route.name, data);
      }
    }
  }
  checkSetting(name: string, data: ILuckMoneyResult) {
    const menu = this.tabmenus.filter(v => v.routeName === name)[0];
    if (Array.isArray(data.list)) {
      let typeSetting: ILuckMoney = {
        error_msg: '',
        status: 0
      };
      switch (menu.key) {
        case 'type1':
          typeSetting = data.list[0].type1;
          break;
        case 'type2':
          typeSetting = data.list[0].type2;
          break;
        case 'type3':
          typeSetting = data.list[0].type3;
          break;
      }

      this.checkMsg = typeSetting ? typeSetting.error_msg : '';
      // if (typeSetting.error_msg) {
      //   this.$router.push({
      //     name: 'ProxyReport'
      //   });
      // }
    }
  }
  unitMap = new Map([
    ['USD', 'ui_dollar_usd'],
    ['VND', 'ui_dollar_vnd'],
    ['THB', 'ui_dollar_thb'],
    ['CNY', 'ui_dollar_cny']
  ]);
  get dollarUnit() {
    const code = this.config.currency ? this.config.currency.code : 'CNY';
    return this.unitMap.get(code);
  }

  getUnit(key: string) {
    if (key) {
      return this.$t(key, {
        dollar: this.$t(this.dollarUnit || 'ui_dollar_cny')
      });
    } else {
      return '';
    }
  }
  openCustom(key: string) {
    //this.$nextTick:避免自订尚未更新就赋值给customValue(tmp)
    this.$nextTick(() => {
      this.customDialog = true;
      switch (key) {
        case 'active_day':
          if (this.settings.active_day) {
            const obj = {
              ...this.settings.active_day,
              key: key
            };
            this.customItem = obj;
            this.customValue = this.info.active_day + '' || '';
          }
          break;
        case 'price':
          this.customItem = this.settings.price || {
            name: '',
            unitTxt: '',
            items: [0],
            itemHilight: 0,
            customItem: [0],
            key: ''
          };
          this.customItem.key = key;
          this.customValue = this.info.price + '' || '';
          break;
        case 'num':
          this.customItem = this.settings.num || {
            name: '',
            unitTxt: '',
            items: [0],
            itemHilight: 0,
            customItem: [0],
            key: ''
          };
          this.customItem.key = key;
          this.customValue = this.info.num + '' || '';
          break;
        case 'threshold':
          this.customItem = this.settings.threshold || {
            name: '',
            unitTxt: '',
            items: [0],
            itemHilight: 0,
            customItem: [0],
            key: ''
          };
          this.customItem.key = key;
          this.customValue = this.info.threshold + '' || '';
          break;
        case 'not_login_day':
          this.customItem = this.settings.not_login_day || {
            name: '',
            unitTxt: '',
            items: [0],
            itemHilight: 0,
            customItem: [0],
            key: ''
          };
          this.customItem.key = key;
          this.customValue = this.info.not_login_day + '' || '';
          break;
      }
      // this.customItem = this.settings[key];
      // this.customItem.key = key;
      // this.customValue = this.info[key] || '';
    });
  }
  confirm() {
    const setting = this.customItem;
    setting.items.splice(-1, 1, this.customValue); //tmp set view
    switch (setting.key) {
      case 'active_day':
        this.info.active_day = +this.customValue || 0;
        break;
      case 'price':
        this.info.price = +this.customValue || 0;
        break;
      case 'num':
        this.info.num = +this.customValue || 0;
        break;
      case 'threshold':
        this.info.threshold = +this.customValue || 0;
        break;
      case 'not_login_day':
        this.info.not_login_day = +this.customValue || 0;
        break;
    }
    // this.info[setting.key] = +this.customValue || ''; //tmp set data
    this.customDialog = false;
  }
  back() {
    this.customDialog = false;
    // this.customKey = '';
  }
  async update() {
    const txt = [];
    const keys = Object.entries(this.info);
    for (const [key, value] of keys) {
      if (!value || (Array.isArray(value) && !value.length)) {
        txt.push('');
      }
    }
    if (txt.length > 0) {
      const opts = {
        confirmButtonText: this.$t('btn_confirm'),
        title: this.$t('sysmsg_please_check_the_activity_settings'),
        message: 'Error',
        zIndex: 3000
      };
      ElMessageBox({
        ...opts,
        message: this.$t('checkSetting')
      });
    } else {
      const data = await luckmoney.updated<ILuckMoneyUpdatedResult>(this.info);
      if (data instanceof Error) {
        ElMessage({type: 'error', message: data.message});
      } else {
        ElMessage(data.message);
        this.doCheck();
      }
    }
  }
  //for 每日送 everyday
  async openMember() {
    console.log(this.info.type, 'this.info.type');
    const data = await luckmoney.member<ILuckMoneyMemberResult>({type: this.info.type});
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
    } else {
      this.memberList = data.list;
    }
    this.allUser = false;
    this.memberDialog = true;
  }
  backUser(e: unknown) {
    console.log('e: ', e);
    if (e) {
      this.memberDialog = false;
    }
    this.userName = '';
    this.chooseUser = [];
  }
  confirmUser() {
    console.log(this.chooseUser, 'chooseUser');
    if (this.chooseUser.length > 0) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.info.choose_user = this.chooseUser.map(v => v);
      this.memberDialog = false;
    } else {
      ElMessage({
        message: this.$t('ui_choice_member')
      });
    }
  }
  getComputed(list: {user_name: string}[]) {
    return list.filter(v => v.user_name.indexOf(this.userName) > -1).slice(0, 500);
  }
  chooseAllUser() {
    this.allUser = !this.allUser;
  }
  termClick(name: string) {
    this.$router.push({name});
  }
}
